import { Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuthState } from "../../../../context/UserAuthentication";
import {
  IGuestContactInfo,
  SignupFormFields,
} from "../../../../models/forms.model";
import { useAppDispatch } from "../../../../redux/store/store";
import { customerBusinessService } from "../../../../services";
import { signupSchema } from "../../../../validationSchemas/createAccountSchema";
import * as CONSTANT from "../../../../constants";
import { getVisitorId } from "../../../../helpers/helperMethods";
import { brazeLogCustomEventLoyaltyAccountCreated, brazeLogEventSubscribedSMSMarketing } from "../../../../helpers/brazeHelper";
import { Toast_Func } from "../../../../helpers/toast.helper";
import { Col, Row } from "react-bootstrap";
import { FormField } from "../../../FormFields/FormField";
import { PasswordField } from "../../../FormFields/PasswordField";
import Button from "../../../Buttons/Button";
import { ROUTE_CHECKOUT } from "../../../../constants";
import { setShowCart } from "../../../../redux/slices/cartSlice";
import PhoneNumberForm from "../../../Forms/PhoneNumberForm";
import SmsCheckbox from "../../../Forms/SmsCheckbox";
interface ISignUpForm {
  closeModal: () => void;
  guestContactInfo?: IGuestContactInfo;
  subTotal?: number;
}
const SignUpForm: React.FC<ISignUpForm> = ({
  closeModal,
  guestContactInfo,
  subTotal,
}) => {
  const history = useHistory();
  const { dispatch: authDispatch } = useAuthState();
  const dispatch = useAppDispatch();
  const [showSignUpForm, setShowSignUpForm] = useState<boolean>(
    !!guestContactInfo
  );
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isMessageSubscription, setIsMessageSubscription] = useState<boolean>(true);
  const SignUpFormhandle = () => {
    setShowSignUpForm(true);
  };
  const guestEarnedPoints = subTotal ? Math.ceil(subTotal * 10) : 0;
  const initialFormState: SignupFormFields = {
    first_name: guestContactInfo?.first_name || "",
    last_name: guestContactInfo?.last_name || "",
    email: guestContactInfo?.email || "",
    password: "",
    confirm_password: "",
    marketing_email_subscription: true,
    birthday: "",
    phone: "",
  };

  const handleFormSubmission = async (
    values: SignupFormFields,
    { setSubmitting }: any
  ) => {
    values.first_name = values.first_name.trim();
    values.last_name = values.last_name.trim();
    values.birthday = values.birthday?.replace("/", "-");
    const visitorId = getVisitorId();
    const modifiedValues = {
      visitor_id: visitorId,
      subscribe_to_sms_marketing: Boolean(phoneNumber?.length) && isMessageSubscription,
      phone: phoneNumber,
      ...values,
    };
    try {
      const res = await customerBusinessService.createAccount(modifiedValues);
      if (res.data.status) {
        authDispatch({ type: "LOGIN_SUCCESS", payload: res.data.data });
        localStorage.setItem(
          CONSTANT.CURRENT_USER,
          JSON.stringify({ ...res.data.data, visitor_id: getVisitorId() })
        );
      }
      setSubmitting(false);
      closeModal();
      history.push(ROUTE_CHECKOUT);
      dispatch(setShowCart(false));
      brazeLogCustomEventLoyaltyAccountCreated(modifiedValues);
      if(phoneNumber.length && isMessageSubscription) {
        setTimeout(
          () => brazeLogEventSubscribedSMSMarketing(res.data.data)
          , 3000);
      }
    } catch (error) {
      values.birthday = values.birthday?.replace("-", "/");
      if (error.response.data.errors) {
        if (Array.isArray(error.response.data.errors)) {
          if (error.response.data.errors.length > 0) {
            for (let key in error.response.data.errors) {
              Toast_Func({
                status: false,
                message: error.response.data.errors[key],
              });
            }
          }
        } else {
          Toast_Func({
            status: false,
            message: error.response.data.errors,
          });
        }
      } else {
        if (typeof error.response.data.message === "object") {
          for (let key in error.response.data.message) {
            Toast_Func({
              status: false,
              message: error.response.data.message[key][0],
            });
          }
        } else {
          Toast_Func({
            status: false,
            message: error.response.data.message,
          });
        }
      }
    }
  };
  return (
    <>
      <div className="new_form_design gap-10">
        <Formik
          initialValues={initialFormState}
          validationSchema={signupSchema}
          onSubmit={handleFormSubmission}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            submitForm,
          }) => (
            <form onSubmit={handleSubmit}>
              {showSignUpForm && (
                <>
                  <Row>
                    <Col md="12">
                      <FormField
                        labelText={"FIRST NAME"}
                        name="first_name"
                        type="text"
                        placeholder="First Name"
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        value={values.first_name}
                      />
                    </Col>
                    <Col md="12">
                      <FormField
                        labelText={"LAST NAME"}
                        name="last_name"
                        type="text"
                        placeholder="Last Name"
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        value={values.last_name}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormField
                        labelText={"EMAIL ADDRESS"}
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        title=""
                        value={values.email}
                      />
                    </Col>
                    {guestContactInfo ? (
                      <p className="f-s14 font-rale mb-0 py-3">
                        Hey! Don’t lose <b>{guestEarnedPoints} points</b> on this
                        order! <br/> Sign up by setting a password and earn free food.
                      </p>
                    ) : null}
                  </Row>
                  <Row>
                    <Col md="12">
                      <PhoneNumberForm setPhoneNumber={ (value) => {
                      setPhoneNumber(value);
                      setFieldValue("phone", value);
                    }} 
                      isCreateAccountPage={true}  
                      className={errors.phone && touched.phone && "input_error"}/>
                      {errors.phone && touched.phone && (
                          <div className="input-feedback clr-dark-red f-s14 font-rale mt-1 d-inline-block">
                            {errors.phone}
                          </div>
                      )}
                    </Col>
                    <Col md="12">
                    <SmsCheckbox setIsMessageSubscription={setIsMessageSubscription} isCreateAccountPage={true} />     
                    </Col>
                  </Row>
                  <Row>
                  <Col md="12">
                      <PasswordField
                        name="password"
                        labelText={"PASSWORD"}
                        placeholder="Password"
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                    <Col md="12">
                      <PasswordField
                        name="confirm_password"
                        labelText={"Confirm Password"}
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                  </Row>
                  <Button
                    type="button"
                    className="d-block btn btn-large add-to-order-right text-uppercase w-100 mt-3 mb-4 text-uppercase"
                    loading={isSubmitting}
                    onClick={submitForm}
                    id={"create-my-account-button"}
                  >
                    create my account
                  </Button>
                </>
              )}

              {!showSignUpForm && (
                <Button
                  type="button"
                  className="btn btn-large w-100 btn-disabled-bg add-to-order-right outline text-uppercase"
                  onClick={SignUpFormhandle}
                >
                  Sign up with email
                </Button>
              )}
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SignUpForm;
