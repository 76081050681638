import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Image from "../../components/Image/Image";
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import * as CONSTANT from "../../constants";
import { useStoreLocation } from "../../context/StoreLocation";
import SignUpButton from "../AccountStatus/SignUpButton";
import UserName from "../AccountStatus/UserName";
import CartModal from "../Cart/CartModal/CartModal";
import { LocationInfoModal } from "../LocationInfo/LocationInfoModal";
import styleClasses from "./Header.module.scss";
import { useAppSelector, useAppDispatch } from "../../redux/store/store";
import { setShowCart } from "../../redux/slices/cartSlice";
import { useAuthState } from "../../context/UserAuthentication";
import OrderType from "../ItemGrid/OrderType";
import Livetracking from "../LiveTracking/Livetracking";
import {
  DELIVERY_ORDER_TYPE,
  GUEST_USER,
  ROUTE_CHECKOUT,
  ROUTE_HOME,
  REACT_APP_CLOUD_FRONT
} from "../../constants";
import useAddToOrder from "../../hooks/useAddToOrder";
import useGetCustomerPlacedOrders from "../../react-query-hooks/useGetCustomerPlacedOrders";
import { guestLogin } from "../../context/UserAuthentication/actions";
import { getUser, getVisitorId } from "../../helpers/helperMethods";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import OrderDetailsMobile from "../../containers/order/OrderDetails/OrderDetailsMobile";
import { Toast_Func } from "../../helpers/toast.helper";
import AddressConfirmationForUser from "../Forms/AddressConfimationForUser";
import useAddAddress from "../../react-query-hooks/useAddAddress";
import PopUpModel from "../../components/Footer/PopUpModel";
import useKeypress from "../../hooks/useKeypress";
import { ROUTE_REWARDS_REDEEM } from "../../constants";
import { NavlinkModel } from "../../models/navlink.model";
import { Helmet } from "react-helmet";
import { getStructuredItemList } from "../../helpers/scripts/seoScript";
import { cartSubTotal } from "../../helpers/cartHelperMethods";
import HeaderTop from "./HeaderTop";
import {
  CartBagIcon,
  DeliveryIcon,
  LocationFilledIcon,
  LocationOutlineIcon,
  DeliveryIconMobile,
  CartBagItemAdded,
  UserIcon,
} from "../../images/svgIcons/svg";
import useGetUsersLoyaltyPoints from "../../react-query-hooks/useGetUsersLoyaltyPoints";
import { updateLoyalityPoints } from "../../redux/slices/itemSlice";
import useKioskLogin from "../../hooks/useKioskLogin";
import useDeepLinkRedirection from "../../hooks/useDeepLinkRedirection";
import OrderTrackBar from "./OrderTrackBar";

const Header: React.FunctionComponent = () => {
  const isItMobile = useCheckMobileScreen();
  const { locationInfo } = useStoreLocation();
  const { removeKioskLoginSession } = useKioskLogin();

  const history = useHistory();
  const { authInfo, dispatch: authDispatch } = useAuthState();
  const Cart = useAppSelector((state) => state.cart);
  const {
    showLocationModal,
    setShowLocationModal,
    setShowCartModal,
    showCartModal,
  } = useDeepLinkRedirection();
  const dispatch = useAppDispatch();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  //Value To know if there is any Live Order (Access Like this)
  const trackLiveOrder = useAppSelector((state) => state.checkout?.trackOrder);

  useEffect(() => {
    if (showCartModal) {
      setShow(true);
    }
    setOpenOrderModal(showLocationModal);
  }, [showCartModal, showLocationModal]);

  let show = Cart.showCart;
  const setShow = (state) => {
    dispatch(setShowCart(state));
  };
  const user = getUser();
  const userId = user.id ?? "";
  const userType = user.type ?? "";
  const authUserId = userType !== GUEST_USER ? authInfo?.userId : null;

  const { addToOrder } = useAddToOrder();
  const location = useLocation();
  const [stickyClass, setStickyClass] = React.useState("remove-sticky");
  const navLinksArray: NavlinkModel[] = [
    {
      text: "HOME",
      link: "/",
      alternateURL: { exist: true, link: "/" },
    },
    {
      text: "ORDER",
      link: CONSTANT.ROUTE_MENU,
      alternateURL: { exist: true, link: CONSTANT.ROUTE_MENU },
    },
    {
      text: "REWARDS",
      link: CONSTANT.ROUTE_FAQ,
      alternateURL: { exist: true, link: ROUTE_REWARDS_REDEEM },
    },
    {
      text: "OUR KITCHEN",
      link: CONSTANT.ROUTE_OUR_KITCHEN,
      alternateURL: { exist: false, link: null },
    },
  ];

  const [showLocationPopup, setLocationPopup] = useState(false);
  const [show3, setShow3] = useState(false);
  const [showLiveTracker, setShowLiveTracker] = useState(false);

  const [addressConfirmationModal, setAddressConfirmationModal] = useState<{
    showModal: boolean;
    showCart: boolean;
  }>({ showModal: false, showCart: false });
  const { mutateAsync: addAddress, isLoading: isAddingAddress } =
    useAddAddress();
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const [isActiveOrders, setIsActiveOrders] = useState<boolean>(true);
  const [onScrollNavHide, setOnScrollNavHide] = useState(false);
  const [onScrollNavPrevPos, setOnScrollNavPrevPos] = useState(100);

  const handleClose = () => {
    setShow(false);
    setShowCartModal(false);
  };
  const handleShow = async () => {
    if (location.pathname.includes(ROUTE_CHECKOUT)) {
      history.push(CONSTANT.ROUTE_MENU);
      setShow(true);
    } else {
      if (authLoading) return;
      const user = getUser();
      if (!user.id) {
        const resp = await authenticateGuest();
        if (resp) {
          if (!Cart.isOrderTypeSelected) {
            setOpenOrderModal(true);
          } else {
            if (verifyAddress())
              setAddressConfirmationModal({ showModal: true, showCart: true });
            else setShow(true);
          }
        }
      } else {
        if (!Cart.isOrderTypeSelected) {
          setOpenOrderModal(true);
        } else {
          if (verifyAddress())
            setAddressConfirmationModal({ showModal: true, showCart: true });
          else setShow(true);
        }
      }
    }
  };
  const { data: userLoyaltyPoints } = useGetUsersLoyaltyPoints(
    authInfo.userId as string,
    authInfo.type
  );

  useEffect(() => {
    dispatch(updateLoyalityPoints(userLoyaltyPoints?.points));
  }, [userLoyaltyPoints]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingDown = currentScrollPos > onScrollNavPrevPos;
      setOnScrollNavHide(isScrollingDown);
      const isAtTop = currentScrollPos === 10;
      setOnScrollNavPrevPos(currentScrollPos);
      if (isAtTop) {
        setOnScrollNavHide(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onScrollNavPrevPos]);

  const authenticateGuest = async () => {
    try {
      setAuthLoading(true);
      const payload = {
        visitor_id: getVisitorId(),
      };
      await guestLogin(authDispatch, dispatch, payload);
      setAuthLoading(false);
      return true;
    } catch (error) {
      setAuthLoading(false);
      return false;
    }
  };
  useEffect(() => {
    if (!user?.id && !authLoading) authenticateGuest();
  }, [user.id]);

  const closeOpenOrderModal = () => {
    setOpenOrderModal(false);
    setShowLocationModal(false);
  };

  const closeLocationPopup = () => setLocationPopup(false);

  const handleClose3 = () => setShow3(false);

  const handleLocationButton = () => {
    if (verifyAddress()) {
      setAddressConfirmationModal({ showModal: true, showCart: false });
      return;
    }
    setOpenOrderModal(true);
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 1) {
      setStickyClass("add-sticky");
    } else {
      setStickyClass("remove-sticky");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const verifyAddress = () => {
    return (
      Cart.orderType === DELIVERY_ORDER_TYPE &&
      authInfo.type !== GUEST_USER &&
      Cart?.orderDetails?.delivery_address?.isGuestAddress
    );
  };

  const closeAddressConfirmationModal = () => {
    if (addressConfirmationModal.showCart) dispatch(setShowCart(true));
    setAddressConfirmationModal({ showCart: false, showModal: false });
  };
  const {
    refetch: refetchGetCustomerPlacedOrders,
    data: { data: placedOrders = [] } = [],
    isFetching: isFetchingPlacedOrders,
  } = useGetCustomerPlacedOrders({ userId: authInfo?.userId });
  useEffect(() => {
    if (authInfo && authInfo?.userId && authInfo?.access_token) {
      refetchGetCustomerPlacedOrders();
    }
  }, [authInfo?.userId]);
  const [orderNo, setOrderNo] = useState<number>(null);
  const orderIds = placedOrders?.map((order) => order.id);
  const state = location.state as { from: string };
  if (state?.from === CONSTANT.HISTORY_STATE_FROM_PLACED_ORDER)
    refetchGetCustomerPlacedOrders();
  const isPublicTrackingRoute = location.pathname.includes(
    CONSTANT.ROUTE_ORDER_TRACKING
  );
  const shouldShowLiveTracker =
    !isFetchingPlacedOrders &&
    placedOrders?.length &&
    !isPublicTrackingRoute &&
    isActiveOrders;

  const handleCloseLiveTracker = () => {
    setShowLiveTracker(false);
    setOrderNo(null);
  };
  const handleShowLiveTracker = () => {
    refetchGetCustomerPlacedOrders();
    setShowLiveTracker(true);
  };
  const handleMobileOrderBackward = () => {
    setOrderNo(null);
    refetchGetCustomerPlacedOrders();
  };

  if (!placedOrders?.length && showLiveTracker) {
    Toast_Func({ status: false, message: "No Order Found" });
    handleCloseLiveTracker();
  }

  const locationLabel = () => {
    if (Cart.isOrderTypeSelected) {
      return Cart.orderType === DELIVERY_ORDER_TYPE ? (
        <>
          {" "}
          {isItMobile ? (
            <>
              {" "}
              <span className={`${styleClasses.borderBottomLocation}`}>
                {" "}
                Delivery
              </span>{" "}
              <DeliveryIconMobile />{" "}
            </>
          ) : (
            <>
              <DeliveryIcon />
              <span className={`${styleClasses.borderBottomLocation}`}>
                {" "}
                Delivery
              </span>
            </>
          )}{" "}
        </>
      ) : (
        (
          <>
            {isItMobile ? (
              <>
                <span className={`${styleClasses.borderBottomLocation}`}>
                  {" "}
                  {locationInfo?.selectedStore?.name}{" "}
                </span>
                <LocationFilledIcon />
              </>
            ) : (
              <>
                <LocationFilledIcon />
                <span className={`${styleClasses.borderBottomLocation}`}>
                  {" "}
                  {locationInfo?.selectedStore?.name}{" "}
                </span>
              </>
            )}
          </>
        ) ?? <> </>
      );
    } else
      return (
        <div>
          {isItMobile ? (
            <>
              <span className={`${styleClasses.borderBottomLocation} `}>
                {" "}
                Locations{" "}
              </span>{" "}
              <LocationOutlineIcon />
            </>
          ) : (
            <>
              <LocationOutlineIcon />
              <span className={`${styleClasses.borderBottomLocation}`}>
                {" "}
                find a cafe zupas{" "}
              </span>{" "}
            </>
          )}{" "}
        </div>
      );
  };

  useKeypress("Escape", () => {
    setShowPopupModal(false);
  });

  const loyaltyRedirectionLink = (item) => {
    return userType === CONSTANT.GUEST_USER || user?.logged_out
      ? item.link
      : item.alternateURL.exist
      ? item.alternateURL.link
      : item.link;
  };

  const [showPopupModal, setShowPopupModal] = useState(false);
  const handleClosePopupModal = () => setShowPopupModal(false);
  const showLoyaltyBanner =
    process.env.REACT_APP_SHOW_LOYALTY_BANNER === "true" ? true : false;
  return (
    <>
      <Helmet>
        <script>
          {`            
             gtag('set', { 'user_id': ${authUserId || null} } );
              document.getElementById("cart_button").addEventListener("click", function () {
                      gtag("event", "view_cart", {
                        user_id: ${authUserId || null},
                        currency: "USD",
                        value: ${cartSubTotal(Cart?.items)},
                        items: ${JSON.stringify(
                          getStructuredItemList(
                            Cart?.items,
                            locationInfo?.selectedStore?.id
                          )
                        )}
                    });
              });   
              `}
        </script>
      </Helmet>

      <nav
        className={`${styleClasses.headerScrollAnimation}  ${
          onScrollNavHide ? "onScrollHeaderHide" : "onScrollHeaderVisible"
        } ${styleClasses.navContainer}`}
      >
        <div
          className={`${styleClasses.wrapper_tracking_bar} wrapper_tracking_bar_app nav-menu-container-wrap `}
          id="header_wrap"
        >
          <Container
            id="header_container"
            fluid
            className={`${styleClasses.nav_wrapper_main_menu} ${stickyClass} ${styleClasses.fixed} nav-wrapper-mob`}
          >
            <div className="px-4"></div>
            <Row className={styleClasses.header_row}>
              <Col
                xs="12"
                className={`${styleClasses.header_logo_wrap} d-flex justify-content-between flex-md-column`}
              >
                {/* Mene Links Wrap */}

                {!isItMobile ? (
                  <div className={`${styleClasses.mainHeaderWrapper} px-4 `}>
                    {shouldShowLiveTracker ? (
                      <OrderTrackBar
                        handleShowLiveTracker={handleShowLiveTracker}
                      />
                    ) : (
                      ""
                    )}
                    <div className="row">
                      <div className="col-md-5">
                        <HeaderTop
                          locationLabel={locationLabel}
                          handleLocationButton={handleLocationButton}
                          userType={userType}
                          shouldShowLiveTracker={shouldShowLiveTracker}
                          handleShowLiveTracker={handleShowLiveTracker}
                        />
                      </div>
                      <a className={`${styleClasses.logo} col-md-2`} href={ROUTE_HOME}>
                        <Image
                          className="sticky-logo"
                          source={`${REACT_APP_CLOUD_FRONT}/images/NewHomePage/logo.png`}
                          alt="sticky logo"
                        />
                      </a>
                      <div
                        className={`d-flex align-items-center justify-content-end col-md-5 ${styleClasses.headerRight}`}
                      >
                        <button
                          type="button"
                          className={`${
                            styleClasses.locationNavSection +
                            " " +
                            (Cart.orderType === DELIVERY_ORDER_TYPE
                              ? styleClasses.online_location_icon
                              : "")
                          } d-flex justify-content-center align-items-center`}
                          onClick={handleLocationButton}
                        >
                          {locationLabel()}
                        </button>
                        {userType !== CONSTANT.GUEST_USER ? (
                          <UserName />
                        ) : (
                          <SignUpButton />
                        )}
                        <button
                          type="button"
                          className={`${styleClasses.addToCartBtn} font-Vcf d-flex align-items-end justify-content-center clr-dark-blue`}
                          onClick={handleShow}
                          id={"cart_button"}
                        >
                          {Cart.items?.length ? (
                            <CartBagItemAdded />
                          ) : (
                            <CartBagIcon />
                          )}

                          <span
                            className={Cart.items?.length && "clr-white-clr "}
                          >
                            {Cart.items?.length}
                          </span>
                        </button>
                      </div>
                      {/* </div> */}
                    </div>

                    <div className={styleClasses.headerInnerDiv}>
                      <ul className="mb-0 ps-0 d-flex align-items-center w-100 justify-content-between">
                        {navLinksArray.map((item, i) => {
                          return (
                            <NavLink
                              className={`${styleClasses.headerNavLinks} header-menu-font`}
                              to={"" + loyaltyRedirectionLink(item)}
                              activeClassName={styleClasses.activeLink}
                              key={i}
                              exact={item.text === "HOME"}
                            >
                              {item.text}
                            </NavLink>
                          );
                        })}
                        <a
                          className={`${styleClasses.headerNavLinks} header-menu-font`}
                          href={CONSTANT.CATERING_CAFE_ZUPAS_URL}
                        >
                          {CONSTANT.CATERING_CAFE_ZUPAS}
                        </a>
                        <NavLink
                          className={`${styleClasses.headerNavLinks} header-menu-font`}
                          to={CONSTANT.ROUTE_FUNDRAISING}
                          activeClassName={styleClasses.activeLink}
                        >
                          FUNDRAISERS
                        </NavLink>
                        <NavLink
                          to={CONSTANT.ROUTE_CAREERS}
                          className={`${styleClasses.headerNavLinks} header-menu-font`}
                          activeClassName={styleClasses.activeLink}
                        >
                          Careers
                        </NavLink>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="w-100">
                    {shouldShowLiveTracker ? (
                      <OrderTrackBar
                        handleShowLiveTracker={handleShowLiveTracker}
                      />
                    ) : (
                      ""
                    )}

                    <HeaderTop
                      locationLabel={locationLabel}
                      handleLocationButton={handleLocationButton}
                      userType={userType}
                      shouldShowLiveTracker={shouldShowLiveTracker}
                      handleShowLiveTracker={handleShowLiveTracker}
                    />

                    <div
                      className={`${styleClasses.header_menu_Wrapper} ${styleClasses.mainHeaderWrapper} d-flex justify-content-between align-items-center w-100  `}
                    >
                      <NavbarComponent
                        isHeader={true}
                        Navlinks={navLinksArray}
                        classes="clr-lt-black header-menu-font"
                        expand="md"
                        userId={userId}
                        shouldShowLiveTracker={shouldShowLiveTracker}
                        showLoyaltyBanner={showLoyaltyBanner}
                        ignorePopUpIdCheck={true}
                      ></NavbarComponent>
                      <a
                        className={styleClasses.logo}
                        href={ROUTE_HOME}
                        aria-label="CZ Logo"
                      >
                        <Image
                          className="sticky-logo"
                          source={`${REACT_APP_CLOUD_FRONT}/images/NewHomePage/logo.png`}
                          alt="sticky logo"
                        />
                      </a>
                      <div
                        className={`d-flex align-items-center  ${styleClasses.headerRight}`}
                      >
                        {userType !== CONSTANT.GUEST_USER ? (
                          <UserName />
                        ) : (
                          <SignUpButton />
                        )}
                        <Link
                          to={
                            userType === CONSTANT.GUEST_USER
                              ? CONSTANT.ROUTE_LOGIN
                              : CONSTANT.ROUTE_PROFILE_DETAILS
                          }
                        >
                          <UserIcon />
                        </Link>
                        <button
                          type="button"
                          className={`${styleClasses.addToCartBtn} font-Vcf d-flex align-items-end justify-content-center clr-dark-blue`}
                          onClick={handleShow}
                          id={"cart_button"}
                        >
                          {Cart.items?.length ? (
                            <CartBagItemAdded />
                          ) : (
                            <CartBagIcon />
                          )}

                          <span
                            className={Cart.items?.length && "clr-white-clr"}
                          >
                            {Cart.items?.length}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          {show ? (
            <CartModal
              addToOrder={addToOrder}
              showModal={show}
              closeModal={handleClose}
            />
          ) : null}
          {/* New Payment Method Modal */}
          <LocationInfoModal
            showModal={showLocationPopup}
            closeModal={closeLocationPopup}
          />
          {/* Redeems Rewards Modal */}
          <Modal
            show={show3}
            backdropClassName="rewards_redeem_modal_backdrop"
            centered
            className="theme-modal-style app_container_wrap rewards_redeem_modal"
          >
            <Modal.Body className="text-center">
              <h3 className="f-s36 font-Cls mb-3">Hang on!</h3>
              <p className="f-s14 font-rale mb-3">
                Make selections for your reward.
              </p>
              <div className={`${styleClasses.redeem_item_box} text-center`}>
                <img
                  src={require("../../images/redeem-item-img.png")}
                  className="img-fluid mb-3"
                  alt="redeem item"
                />
                <h4 className="f-s18 font-rale reset-text mb-3">Half Salad</h4>
                <h5 className="overline_text text-uppercase">
                  Use this reward by 9/17/21
                </h5>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-large dark-grey w-100 mb-2"
                >
                  CUSTOMIZE
                </button>
                <button
                  type="button"
                  className="btn btn-large dark-grey w-100 outline bg-white"
                  onClick={handleClose3}
                >
                  Save reward for later
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={openOrderModal}
            centered
            className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
            onHide={closeOpenOrderModal}
            size="sm"
          >
            <Modal.Header className="x-4 justify-content-center">
              <button
                type="button"
                className="btn modal-close-button pe-0 sm-blue-icon"
                onClick={closeOpenOrderModal}
              >
                Close
              </button>
            </Modal.Header>
            <Modal.Body>
              <OrderType
                itemPath={null}
                handleOrderTypeModal={closeOpenOrderModal}
              />
            </Modal.Body>
          </Modal>
          <Modal
            show={addressConfirmationModal.showModal}
            centered
            className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
            onHide={() => {
              !isAddingAddress &&
                setAddressConfirmationModal({
                  showCart: false,
                  showModal: false,
                });
            }}
            size="sm"
          >
            <Modal.Header className="x-4 justify-content-center">
              <button
                type="button"
                className="btn modal-close-button pe-0 sm-blue-icon"
                onClick={() => {
                  !isAddingAddress &&
                    setAddressConfirmationModal({
                      showCart: false,
                      showModal: false,
                    });
                }}
              >
                Close
              </button>
            </Modal.Header>
            <Modal.Body>
              <AddressConfirmationForUser
                closeModal={closeAddressConfirmationModal}
                addAddress={addAddress}
                isAddingAddress={isAddingAddress}
              />
            </Modal.Body>
          </Modal>

          <div className="LiveTracking">
            <Modal
              show={showLiveTracker}
              centered
              className="live_tracking_modal theme-modal-style app_container_wrap border-radius-15 px-5 full-modal-ipad"
              onHide={handleCloseLiveTracker}
              size="xl"
            >
              <Modal.Header className="x-4 justify-content-center">
                <button
                  type="button"
                  className="btn modal-close-button pe-0"
                  onClick={handleCloseLiveTracker}
                >
                  Close
                </button>
              </Modal.Header>
              <Modal.Body>
                {isItMobile && orderNo === null ? (
                  <>
                    <OrderDetailsMobile
                      setOrderNo={setOrderNo}
                      orders={placedOrders}
                      isFetchingOrders={isFetchingPlacedOrders}
                    />
                  </>
                ) : (
                  <Livetracking
                    selectedOrderNo={orderNo}
                    handleMobileOrderBackward={handleMobileOrderBackward}
                    orderIds={orderIds}
                    handleClose={handleCloseLiveTracker}
                    isFetchingOrders={isFetchingPlacedOrders}
                    titlespace={styleClasses.order_detail_live}
                    setIsActiveOrders={setIsActiveOrders}
                  />
                )}
              </Modal.Body>
            </Modal>
          </div>

          <Row>
            <Col xs="12" sm="12" md="6">
              <PopUpModel
                showPopup={showPopupModal}
                closePopup={handleClosePopupModal}
              />
            </Col>
          </Row>
        </div>
      </nav>
    </>
  );
};

export default Header;
