
import { useQuery } from "react-query";
import axios from "axios";
import { ORDER_MICRO_SERIVCE } from "../constants";
import { updateTrackOrder } from "../redux/slices/checkoutSlice";
import { useAppDispatch } from "../redux/store/store";

interface IQueryParams {
  userId: number;
}

const getCustomerPlacedOrders = async ({ userId }: IQueryParams, dispatch: any) => {
  const response = await axios.get(
    `${ORDER_MICRO_SERIVCE}/orders/track-here/${userId}?page=1&size=25`
  );
  dispatch(updateTrackOrder(response?.data?.data?.data?.length ? true : false));
  return response?.data?.data;
};

export default function useGetCustomerPlacedOrders({ userId }: IQueryParams) {
  const dispatch = useAppDispatch();
  
  return useQuery(
    ["placedOrders", userId],
    () => getCustomerPlacedOrders({ userId }, dispatch),
    {
      enabled: !!userId,
    }
  );
}